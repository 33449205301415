import {
  EditorSDK,
  ComponentRef,
  ComponentDefinition,
  PagesPanelTabType,
  ActionType,
} from "@wix/platform-editor-sdk";
import { requiredComponentsDefinitionMap } from "./utils/componentsDefinition";
import componentConfig from "./components/custom Sign In/.component.json";
import createAppAPI from "./editor/appAPI";
import {
  ACTION_IDS,
  POPUP_SETTINGS,
  SETTINGS_ADD_ID,
} from "./components/custom Sign In/editor.controller";
import { ComponentsOnAddElements } from "@wix/platform-editor-sdk/lib/js/modules/editor";
import {
  IMissingComponent,
  IRequiredComponentsDefinitionMapKeys,
  IRequiredComponentsDefinitionMap,
} from "./components/custom Sign In/types";
import {
  creatingTheApp,
  isEssentialComponent,
  openFieldSettingsPanelWithoutInputType,
  rebaseTheApp,
  addElementToWidget,
  getFireNotification,
  getConditionallyDebounceAQueue,
  controllerAlreadyExists,
} from "./utils/editor";
import {
  siteMembersCustomLoginOpenLoginElementsPanel,
  siteMembersCustomLoginAddElementFromLoginElementsPanel,
  siteMembersCustomLoginDeleteElement,
  siteMembersCustomLoginResetToDefaultFromLoginSettings,
} from "@wix/bi-logger-site-members/v2";
import { Logger } from "@wix/web-bi-logger/dist/src/types";
import { EditorReadyFn, TFunction } from "@wix/yoshi-flow-editor";

let logger: Logger;

let _requiredComponentsDefinitionMapCache: ReturnType<
  typeof requiredComponentsDefinitionMap
>;
let _appDefinitionId: string;
let _baseUrl: string;
let _t: TFunction;
let _environmentType: string;

const componentId = componentConfig.id;

const isResponsiveEnvironment = () => _environmentType === "RESPONSIVE";

const formatMissingComponentsToAddElements = (
  missingComponents: IMissingComponent[],
  t: TFunction
): ComponentsOnAddElements[] => {
  return missingComponents.map((component) => {
    return {
      label: t(`addElements.titles.${component.key}`),
      isEssential: isEssentialComponent(component.key),
      essentialText: "Mandatory",
    };
  });
};

const getMissingComponents = (
  requiredList: Record<
    IRequiredComponentsDefinitionMapKeys,
    ComponentDefinition
  >,
  existList: Record<string, any>
) => {
  return Object.entries(requiredList).reduce(
    (acc: IMissingComponent[], [key, val]) => {
      if (!existList[key]) {
        acc.push({ key, componentDef: val });
      }

      return acc;
    },
    []
  );
};

const getCurrentListOfConnectedComponents = async (
  widgetRef: ComponentRef,
  editorSDK: EditorSDK
) => {
  const componentsRef = await editorSDK.document.controllers.listConnectedComponents(
    "token",
    { controllerRef: widgetRef }
  );
  const connectedComponents = await editorSDK.components.get("token", {
    componentRefs: componentsRef,
    properties: ["connections"],
  });
  const connectedComponentsRecord = connectedComponents.reduce(
    (acc: Record<string, ComponentRef>, component: any) => {
      acc[component.connections[0].role] = component.componentRef;
      return acc;
    },
    {}
  );
  return connectedComponentsRecord;
};

export async function openAddPanel(
  editorSDK: EditorSDK,
  { componentRef }: any,
  _requiredComponentsDefinitionMap: IRequiredComponentsDefinitionMap,
  t: TFunction
) {
  const currentListOfRequiredComponents = await getCurrentListOfConnectedComponents(
    componentRef,
    editorSDK
  );
  const missingComponents = getMissingComponents(
    // @ts-expect-error ignore responsiveLayout for now
    _requiredComponentsDefinitionMap,
    currentListOfRequiredComponents
  );
  const addPanelItems = formatMissingComponentsToAddElements(
    missingComponents,
    t
  );
  editorSDK.editor.openAddElementsPanel("token", {
    components: addPanelItems,
    shouldHaveReset: true,
    widgetRef: componentRef,
  });
}

const conditionallyDebounceAQueue = getConditionallyDebounceAQueue(1000);

export async function onEvent(event: any, editorSDK: EditorSDK) {
  const payload = event.eventPayload;
  const label: IRequiredComponentsDefinitionMapKeys = payload?.label;
  const t = await _t;
  const _requiredComponentsDefinitionMap = (_requiredComponentsDefinitionMapCache = _requiredComponentsDefinitionMapCache
    ? _requiredComponentsDefinitionMapCache
    : requiredComponentsDefinitionMap(t, isResponsiveEnvironment()));
  switch (event.eventType) {
    case "componentGfppClicked":
      switch (payload.id) {
        case POPUP_SETTINGS:
          const pageRef = await editorSDK.pages.getCurrent(_appDefinitionId);

          // TODO: We need replace it with a deep link see:
          // https://bo.wix.com/wix-docs/client/editor-platform/classic-editor-sdk/editor/deeplink#editor-platform_classic-editor-sdk_editor_deeplink_show
          editorSDK.editor.openPagesPanel(_appDefinitionId, {
            pageRef,
            initialSettingsTabType: "PAGE_INFO" as PagesPanelTabType,
          });
          break;
        case ACTION_IDS.EMAIL_SETTINGS:
        case ACTION_IDS.PASSWORD_SETTINGS:
          openFieldSettingsPanelWithoutInputType({
            appDefinitionId: _appDefinitionId,
            componentRef: payload.componentRef,
            editorSDK,
          });
          break;
        case ACTION_IDS.SUBMIT_SETTINGS:
          editorSDK.editor.openNativeComponentPanel(
            _appDefinitionId,
            "settings" as ActionType,
            {
              componentRef: payload.componentRef,
              panelSectionsDefinition: {
                link: "hidden",
              },
            }
          );
          break;
        default:
          break;
      }
      break;
    case "widgetGfppClicked":
      if (payload.id === SETTINGS_ADD_ID) {
        openAddPanel(editorSDK, payload, _requiredComponentsDefinitionMap, t);
        logger?.report(
          siteMembersCustomLoginOpenLoginElementsPanel({
            hosting: "editor",
          })
        );
      }
      break;
    case "addElementsCompClicked":
      const _requiredComponentsDefinitionMapTranslated = Object.keys(
        _requiredComponentsDefinitionMap
      ).reduce((acc: Record<string, string>, key) => {
        acc[t(`addElements.titles.${key}`) as string] = key;
        return acc;
      }, {});
      await addElementToWidget(
        editorSDK,
        payload.widgetRef,
        _requiredComponentsDefinitionMapTranslated[
          label
        ] as IRequiredComponentsDefinitionMapKeys,
        _requiredComponentsDefinitionMap,
        t,
        isResponsiveEnvironment()
      );
      logger?.report(
        siteMembersCustomLoginAddElementFromLoginElementsPanel({
          hosting: "editor",
          element_name: label,
        })
      );
      break;
    case "addElementsAllCompsClicked":
      const currentListOfRequiredComponents = await getCurrentListOfConnectedComponents(
        payload.widgetRef,
        editorSDK
      );
      const missingComponents = getMissingComponents(
        // @ts-expect-error ignore responsiveLayout for now
        _requiredComponentsDefinitionMap,
        currentListOfRequiredComponents
      );

      await Promise.all(
        missingComponents.map(({ key }) => {
          return addElementToWidget(
            editorSDK,
            payload.widgetRef,
            key as IRequiredComponentsDefinitionMapKeys,
            _requiredComponentsDefinitionMap,
            t,
            isResponsiveEnvironment()
          );
        })
      );
      break;
    case "componentDeleted":
      const componentLabel: IRequiredComponentsDefinitionMapKeys =
        payload.connections?.[0].role;
      logger?.report(
        siteMembersCustomLoginDeleteElement({
          hosting: "editor",
          element_name: componentLabel,
        })
      );
      const fireNotification = conditionallyDebounceAQueue(
        getFireNotification({
          editorSDK,
          t,
          _requiredComponentsDefinitionMap,
          logger,
          isResponsive: isResponsiveEnvironment(),
        }),
        // If the component doesn't required we are not firing an essential message or if we just reset the widget.
        // in case the form container (tha whole app) is being deleted we don't want to fire any notification.
        (events) =>
          !events.find(
            ({ componentLabel: _componentLabel }) =>
              // @ts-expect-error
              _componentLabel === "formContainer"
          )
      );
      fireNotification({ componentLabel, payload });
      break;
    case "addElementsResetClicked":
      await rebaseTheApp(
        editorSDK,
        _appDefinitionId,
        componentId,
        t,
        isResponsiveEnvironment()
      );
      const _currentListOfRequiredComponents = await getCurrentListOfConnectedComponents(
        payload.componentRef,
        editorSDK
      );
      logger?.report(
        siteMembersCustomLoginResetToDefaultFromLoginSettings({
          hosting: "editor",
        })
      );
      break;
    default:
      break;
  }
}

export const editorReady: EditorReadyFn = async (
  editorSDK,
  appDefinitionId,
  event,
  flowAPI,
) => {
  try {
    try {
      logger = event.essentials
        .biLoggerFactory({ endpoint: "site-members" })
        .withUoUContext({
          msid: await editorSDK.info.getMetaSiteId(appDefinitionId),
          siteMemberId: await editorSDK.info.getUserId(appDefinitionId),
        })
        .logger();
    } catch (e) {
      console.log("sm-platform-app error -> Failing to initiate logger: ", e);
    }
    _appDefinitionId = appDefinitionId;
    _environmentType = event.origin?.type || "";
    _baseUrl =
      process.env.NODE_ENV === "development"
        ? "https://localhost:3200"
        : event.initialAppData.editorScriptUrl.replace(
            "/editorScript.bundle.min.js",
            ""
          );
    _t = (await flowAPI.translations.init()).t as TFunction
    const t = _t;
    editorSDK.editor.getAppAPI();
    if (
      event.firstInstall ||
      // @ts-ignore type mismatch between different editorSDK interface sources
      !(await controllerAlreadyExists(componentId, editorSDK, appDefinitionId))
    ) {
      // Register an API for the app settings
      await editorSDK.editor.setAppAPI(
        appDefinitionId,
        // @ts-ignore type mismatch between different editorSDK interface sources
        createAppAPI(editorSDK, appDefinitionId)
      );

      await creatingTheApp({
        // @ts-ignore type mismatch between different editorSDK interface sources
        editorSDK,
        appDefinitionId,
        componentId,
        t,
        isResponsive: isResponsiveEnvironment(),
      });
    }
  } catch (e) {
    console.log("sm-platform-app error -> editorReady: ", e);
  }
};
